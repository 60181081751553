import * as React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
export default function LineStat() {
  const data = [
    { name: "Jan", fraud: 4000, notfraud: 2400 },
    { name: "Feb", fraud: 3000, notfraud: 1398 },
    { name: "Mar", fraud: 2000, notfraud: 6800 },
    { name: "Apr", fraud: 2780, notfraud: 3908 },
    { name: "May", fraud: 1890, notfraud: 4800 },
    { name: "Jun", fraud: 2390, notfraud: 3800 },
    { name: "Jul", fraud: 3490, notfraud: 4300 },
    { name: "Aug", fraud: 4000, notfraud: 2400 },
    { name: "Sep", fraud: 3000, notfraud: 1398 },
    { name: "Oct", fraud: 2000, notfraud: 6800 },
    { name: "Nov", fraud: 2780, notfraud: 3908 },
    { name: "Dec", fraud: 1890, notfraud: 4800 },
  ];
  return (
    <>
      <ResponsiveContainer
        width="90%"
        height={270}
        className="shadow-inner bg-white rounded-2xl mt-[10px] mx-auto mb-[50px] py-4"
      >
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="0" />
          <Tooltip />
          <Legend />
          <Line
          isAnimationActive={true} animationDuration={1500}
            type="monotone"
            dataKey="fraud"
            stroke="#FF0000"
            strokeWidth={2}
            activeDot={{ r: 8 }}
            dot={{ r: 2, fill: "#FFFFFF", stroke: "#FF0000", strokeWidth: 3 }}
            style={{
              filter: "drop-shadow(0px 0px 5px rgba(255, 0, 0, 0.8))",
            }}
          />
          <Line
            type="monotone"
            dataKey="notfraud"
            stroke="#1aeb2b"
            dot={{ r: 2, fill: "#FFFFFF", stroke: "#00FF00", strokeWidth: 3 }}
            style={{
              filter: "drop-shadow(0px 0px 5px rgba(0, 255, 0, 0.8))",
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
