import { useState } from "react";
import useHttp from "../../../hooks/useHttp";
import { getAlertData, assignAlert,setAlertVerdict } from "../../../service/Endpoints";
import { useOktaAuth } from "@okta/okta-react";
import { AlertDataRespose } from "../../../dto/ResponeDTO.ts";
import { useEffect, useCallback, useContext } from "react";
import Loader from "../../utils/Loader.jsx";
import { OverlayContext } from "../../utils/OverLay.jsx";
import { useNotification } from "../../utils/Notification/NotificationContext.jsx";
import { green, red, yellow } from '@mui/material/colors';
import { Card, CardContent, Typography, Divider, Box, Grid, Chip, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { format } from 'date-fns';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const AlertCard = ({ data }) => {
  const { alertId, alertType, status, assignedTo, createdOn, assignedOn, assignmentHistory=[] } = data;

  // Helper to format date
  const formatDate = (dateArray) => {
    return format(new Date(dateArray[0], dateArray[1] - 1, dateArray[2]), 'yyyy-MM-dd');
  };

    // Status color based on the status value
    const getStatusColor = () => {
      switch (status) {
        case 'ASSIGNED':
          return yellow[700];
        case 'COMPLETED':
          return green[600];
        case 'NEW':
          return red[500];
        default:
          return 'text.primary';
      }
    };
  return (
<Card sx={{ width: 600, margin: 'auto', boxShadow: 3, borderRadius: 2, backgroundColor: '#f7f7f7', marginLeft:"10px",marginTop:"10px" }}>
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Alert Details
        </Typography>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
            <AssignmentIcon fontSize="small" sx={{ mr: 1, color: 'primary.main' }} />
            Alert ID: {alertId}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
            <EventIcon fontSize="small" sx={{ mr: 1, color: 'secondary.main' }} />
            Created On: {createdOn ? <Chip label={formatDate(createdOn.split('-'))} size="small" color="primary" /> : ""}
          </Typography>
        </Box>

        <Typography variant="h6" gutterBottom sx={{ fontSize: '1.2rem', color: 'primary.dark' }}>
          {alertType ? alertType.replace('_', ' ').toUpperCase() : ""}
        </Typography>

        <Divider />

        <Box sx={{ mt: 2 }}>
          <Typography variant="body1">
            <strong>Status:</strong> 
            <span style={{ color: getStatusColor() }}> {status}</span>
          </Typography>
          <Typography variant="body1" sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
            <PersonIcon fontSize="small" sx={{ mr: 1, color: 'secondary.main' }} />
            <strong>Assigned To:</strong> {assignedTo}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Assigned On: {assignedOn ? <Chip label={formatDate(assignedOn)} size="small" color="secondary" /> : ""}
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="assignment-history-content"
            id="assignment-history-header"
          >
            <Typography variant="h6" color="text.secondary">
              Assignment History
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {assignmentHistory != null && assignmentHistory.map((history, index) => (
              <Box key={index} sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      From: <Chip label={formatDate(history.from)} size="small" sx={{ backgroundColor: '#e0e0e0' }} />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      To: <Chip label={formatDate(history.to)} size="small" sx={{ backgroundColor: '#e0e0e0' }} />
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="body2" color="text.secondary">
                  Assigned to: {history.assignee}
                </Typography>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};
const styleEditAlert = {
  container:
    "absolute transition delay-150 left-full top-0 rounded-2xl right-0 bottom-0 m-auto bg-white w-full h-full z-10",
  btn: "h-10 w-36 shadow-xl shadow-fuchsia-200 rounded-lg text-fuchsia-700 text-2xl transition ease-in-out delay-20  hover:scale-110 hover:duration-300 active:shadow-inner active:shadow-fuchsia-300",
};

const confirmationWindow = (confirmationHandler, msg) => {
  return (
    <>
      <div
        class="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      class="h-6 w-6 text-red-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                      class="text-base font-semibold leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Confirm Action
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">{msg}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={() => confirmationHandler("confirm")}
                  class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Confirm
                </button>
                <button
                  type="button"
                  onClick={() => confirmationHandler("cancel")}
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const EditAlert = ({ showEditAlert, onCloseEditAlertHandler, alertId,dataChangeHandler }) => {
  //hooks
  const { isLoading, error, sendRequest: callApi } = useHttp();
  const { authState } = useOktaAuth();

  //state
  const [alertDetails, setAlertDetail] = useState(new AlertDataRespose());
  const [assignedToUser, setAssignedToUser] = useState(false);
  const [loading, setLoading] = useState(true);

  //context
  const overlayContext = useContext(OverlayContext);
  const { showNotification } = useNotification();

  const logedInUserName=authState.idToken.claims.name;
  const logedInUserId=authState.idToken.claims.sub;

  let verdictValue=0;

  useEffect(() => {
    setLoading(true);
    getAlertsFromAPI();
  }, [alertId]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    console.log(error)
    error && showNotification(error,"error");
  }, [error]);

  const assignAlertToUser = () => {
    let msg=`Are you sure you want to assign alert ${alertDetails.alertId} to ${logedInUserName}`
    if(alertDetails.assignedTo)
      {
        msg=`Are you sure you want to assign alert ${alertDetails.alertId} to ${logedInUserId} from ${alertDetails.assignedTo}`
      }
    overlayContext.setOverlayContent(() =>
      confirmationWindow(
        onAssignmentConfirmationHandler,
        msg
      )
    );
    overlayContext.setShowOverlay(true);
  };

  const setVerdict = (verdict) => {
    if(authState.isAuthenticated && logedInUserId==alertDetails.assignedToId){
      verdictValue=verdict=="Fraud"?-1:1
      overlayContext.setOverlayContent(() =>
        confirmationWindow(
          onVerdictConfirmationHandler,
          `Set Alert ${alertDetails.alertId} as ${verdict}`
        )
      );
      overlayContext.setShowOverlay(true);
    }
    else {
      showNotification("You are not authorized to perform this action","error")
    }
  };


  const onVerdictConfirmationHandler = (action) => {
    overlayContext.setShowOverlay(false);
    if (action === "confirm") {
      let token = authState.accessToken.accessToken;
      callApi(
        {url:setAlertVerdict(alertDetails.alertId,verdictValue)},
        (responseData) => {
          verdictValue=0
          showNotification("Verdict Set Successfully","success");
          onCloseEditAlertHandler()
          dataChangeHandler()
        },
        token
      );
    }
  };

  const onAssignmentConfirmationHandler = (action) => {
    overlayContext.setShowOverlay(false);
    if (action === "confirm") {
      let token = authState.accessToken.accessToken;
      callApi(
        { url: assignAlert(alertDetails.alertId) },
        (responseData) => {
          getAlertsFromAPI()
          showNotification("User Assigned","success");
          dataChangeHandler()
          setAssignedToUser(true);
        },
        token
      );
    }
  };

  const getAlertsFromAPI = useCallback(() => {
    setLoading(true);
    if (alertId) {
      let token = authState.accessToken.accessToken;
      callApi(
        { url: getAlertData(alertId) },
        (responseData) => {
          const response = new AlertDataRespose();
          response.applyData(responseData);
          setAlertDetail(response);
          if (authState.accessToken.claims.uid === response.assignedToId)
            setAssignedToUser(true);
          else setAssignedToUser(false);
        },
        token
      );
    }
    setLoading(false);
  });
  return (
    <div
      className={`z-[1000] ${styleEditAlert.container} ${
        showEditAlert && "-translate-x-full"
      }`}
    >
      <div className="font-bold text-2xl m-auto z-[1000]">
        <button
          onClick={onCloseEditAlertHandler}
          className="shadow 
                       h-10 w-10 
                       border-3 
                       rounded-xg 
                       text-slate-500 
                       text-2xl 
                       transition 
                       ease-in-out 
                       delay-20 
                       hover:text-red-700  
                       hover:duration-300 
                       active:shadow-inner 
                       active:shadow-white-300"
        >
          <vwc-icon type="close-small-solid"></vwc-icon>
        </button>

        {loading?<Loader />:<>
        <div className="flex flex-row w-full justify-center">
          <div>
          <AlertCard data={alertDetails} />
          <div className=" w-full flex flex-row justify-center">
            {assignedToUser ? (
              <>
                <div
                  className="font-bold text-lg m-6 px-1 shadow-lg shadow-gray-400 h-8 w-[150px] rounded-lg text-red-700 text-2xl border-2 border-2 border-red-600 text-center
                              transition ease-in-out delay-20  hover:-translate-y-1 hover:scale-90 hover:duration-300 active:shadow-inner active:shadow-shadow-slate-600"
                >
                  <button onClick={() => {setVerdict("Fraud")}}>FRAUD</button>
                </div>
                <div
                  className="font-bold text-lg m-6 
                         px-1 shadow-lg shadow-gray-400 h-8 w-[150px] rounded-lg text-green-600 text-2xl border-2 border-green-600 text-center
                              transition ease-in-out delay-20  hover:-translate-y-1 hover:scale-90 hover:duration-300 active:shadow-inner active:shadow-shadow-slate-600"
                >
                  <button className="text-center" onClick={() => {setVerdict("Not Fraud")}}>
                    NOT FRAUD
                  </button>
                </div>
              </>
            ) : (
              <div
                className="font-bold 
                         text-lg m-6 
                         px-1
                         shadow-lg
                         text-center
                         shadow-gray-400 h-8 w-[150px] rounded-lg text-green-600 text-lg border-2 border-green-600 
                         transition ease-in-out delay-20  hover:-translate-y-1 hover:scale-90 hover:duration-300 active:shadow-inner active:shadow-shadow-slate-600"
              >
                <button
                  className="text-center"
                  onClick={() => {
                    assignAlertToUser(alertDetails.alertId);
                  }}
                >
                  Assign To Me
                </button>
              </div>
            )}
          </div>
          </div>
          <div
            id="style-scroll-bar"
            className="min-w-[600px] overflow-y-auto h-[75vh] text-lg font-normal shadow-inner bg-gray-50 m-2 p-[10px]  rounded-xl shadow-inner"
          >
            {!alertId || !alertDetails || !alertDetails.details ? (
              <div>No Data Found</div>
            ) : (
              <Box
              sx={{
                fontSize: '0.870rem',
                lineHeight: 1.5,
                color: 'text.secondary',
              }}
              dangerouslySetInnerHTML={{ __html: alertDetails.details }}
            />
            )}
          </div>
          </div>

        </>}
      </div>
    </div>
  );
};

export default EditAlert;
