import assignedIcon from "../../../assets/assigned_icon.png";
import "@vonage/vwc-icon";
import * as React from "react";
import EditAlert from "./EditAlert.jsx";
import { useState, useEffect, useContext } from "react";
import {getAllAlerts} from "../../../service/Endpoints.js";
import { useOktaAuth } from "@okta/okta-react";
import useHttp from "../../../hooks/useHttp.js";
import { Divider } from "@mui/material";
import { AlertDetailsRespose } from "../../../dto/ResponeDTO.ts";
import Pagination from "@mui/material/Pagination";
import { PagableFilter } from "../../../dto/ApplicationDTO.ts";
import { PageLoaderContext } from "../../utils/PageLoader.jsx";
import FilterComponent from "./Filter.jsx";

const styleAlertCard = {
  card: "bg-white m-auto mb-10 h-[80px] rounded-2xl bg-opacity-90 flex flex-row drop-shadow-md overflow-x-hidden alert-card-entry-animation",
  nameToolTip:
    "absolute bottom-14 left-20 scale-0 rounded-2xl bg-fuchsia-100 p-2 text-sm text-neutral-950 group-hover:scale-100",
  nameContainer: "text-base mx-5 text-gray-500 w-[350px]",
  type: "text-base mt-2 text-purple-600",
  typeToolTip:
    "absolute bottom-14 left-200 scale-0 rounded-2xl bg-fuchsia-100 font-normal p-2 text-sm text-neutral-950 group-hover:scale-100",
  calenderToolTip:
    "absolute bottom-[10px] right-[100px] scale-0 rounded-2xl bg-fuchsia-100 font-normal p-2 text-sm text-neutral-950 group-hover:scale-100",
  new: "text-center",
  newContainer: "font-bold text-xl mx-5 mt-6 text-red-600",
  assigned:
    "font-bold text-xs mt-2 text-blue-600 flex flex-row text-wrap w-fit",
  calander:
    "flex flex-row text-xs text-center text-gray-400 rounded-lg font-medium",

  editButton:
    "shadow-md h-8 w-8 rounded-xs text-fuchsia-700 text-sm transition ease-in-out delay-20  hover:-translate-y-1 hover:scale-110 hover:duration-300 active:shadow-inner active:shadow-shadow-slate-600",
};

const stylePagination = {
  container:
    "flex flex-row justify-center mt-10 absolute m-auto w-full bottom-[30px]",
  btn: "h-10 shadow-xl shadow-fuchsia-200 rounded-lg text-fuchsia-700 text-2xl transition ease-in-out delay-20  hover:scale-110 hover:duration-300",
};

const alertDataCard = (
  { alertId, alertType, assignedTo, createdOn },
  onEditAlertHandler
) => {
  return (
    <>
      <div key={alertId} className={styleAlertCard.card}>
        <div className={styleAlertCard.nameContainer}>
          <span className="text-gray-400 text-[13px]">Alert Id: </span>
          <span className={"font-semibold tracking-tighter text-[13px]"}>
            {alertId}
          </span>
          <div className={styleAlertCard.type}>
            <span className="text-gray-400 text-[13px]">Type: </span>
            <span className="font-medium tracking-tighter text-[13px]">
              {alertType}
            </span>
          </div>
          <div className="text-blue-700 text-xs bg-indigo-100 pl-2 pr-2 rounded-2xl w-fit tracking-tighter">
            <span className="">Created On: </span>

            <span className="font-bold">{createdOn}</span>
          </div>
        </div>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginX: 1, marginY: 1 }}
        />
        <div className={styleAlertCard.assigned}>
          <img
            src={assignedIcon}
            style={{
              width: 25,
              height: 25,
              marginRight: 1,
            }}
          />
          {assignedTo ? (
            <div className="text-wrap w-[200px]">
              {assignedTo}
              <div className={styleAlertCard.calander}>
                <div>
                  <>assigned On:</>
                </div>
                <div className="">{createdOn}</div>
              </div>
            </div>
          ) : (
            <div className="text-wrap text-red-600 w-[200px]">N/A</div>
          )}
        </div>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ marginX: 1, marginY: 1 }}
        />
        <div className="text-sm w-[40px]">
          <button
            onClick={() => onEditAlertHandler(alertId)}
            className={styleAlertCard.editButton}
          >
            <vwc-icon type="compose-solid"></vwc-icon>
          </button>
        </div>
      </div>
    </>
  );
};

export default function DataViewer({ notFilter = [], fixedFilter = [] }) {
  //State---------------------------------------------------------------------------------
  const [alerts, setAlerts] = useState(new AlertDetailsRespose());
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, serSortOrder] = useState("asc");
  const [totalPage, setTotalPage] = useState(0);
  const [showEditAlert, setShowEditAlert] = useState(false);
  const [selectedAlertId, setSelectedAlertId] = useState();
  const [paginationFilter, setPaginationFilter] = useState(new PagableFilter());
  //---------------------------------------------------------------------------------State

  //hooks---------------------------------------------------------------------------------
  const { authState, oktaAuth } = useOktaAuth();
  const { isLoading, error, sendRequest: callApi } = useHttp();
  //---------------------------------------------------------------------------------hooks

  //Context---------------------------------------------------------------------------------
  const pageLoaderContext = useContext(PageLoaderContext);
  //---------------------------------------------------------------------------------Context

  //SideEffects---------------------------------------------------------------------------------
  useEffect(() => {
    pageLoaderContext.showLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setTotalPage(Math.ceil(alerts.totalRecords / pageLimit));
  }, [alerts]);

  useEffect(() => {
    let pageable = new PagableFilter();
    pageable.limit = pageLimit;
    pageable.offset = 0;
    pageable.sortBy = sortField;
    pageable.sortDir = sortOrder;
    setPaginationFilter(pageable);
  }, []);

  useEffect(() => {
    if (paginationFilter.limit > -1 && paginationFilter.offset > -1)
      getAlertsFromAPI(paginationFilter);
  }, [paginationFilter]);
  //---------------------------------------------------------------------------------SideEffects

  //Constants---------------------------------------------------------------------------------
  const sortField = "recievedOn";
  const sortTypes = ["Oldest", "Newest"];
  const pageLimit = 20;
  //---------------------------------------------------------------------------------Constants

  //API Calls---------------------------------------------------------------------------------
  const getAlertsFromAPI = React.useCallback((pagableFilter) => {
    let newPagination = { ...pagableFilter };
    newPagination.filters = pagableFilter.filters.concat(fixedFilter);
    let token = authState.accessToken.accessToken;
    callApi(
      { url: getAllAlerts(newPagination) },
      (responseData) => {
        const response = new AlertDetailsRespose();
        response.applyData(responseData);
        setAlerts(response);
      },
      token
    );
  });
  //---------------------------------------------------------------------------------API Calls

  //Functions---------------------------------------------------------------------------------
  const onEditAlertHandler = (alertId) => {
    setSelectedAlertId(alertId);
    setShowEditAlert(true);
  };
  const onCloseEditAlertHandler = () => {
    setShowEditAlert(false);
  };

  const onSortChangeHandler = (sortType) => {
    if (sortType == 1) {
      serSortOrder("asc");
    }
    if (sortType == 2) {
      serSortOrder("desc");
    }
  };

  const onSearchHandler = (pageable) => {
    pageable.limit = pageLimit;
    pageable.offset = 0;
    setCurrentPage(1);
    setPaginationFilter(pageable);
  };

  const onPaginationChangeHandler = (page) => {
    setPaginationFilter((prev) => {
      let pageable = new PagableFilter();
      setCurrentPage(page);
      pageable.filters = prev.filters;
      pageable.limit = pageLimit;
      pageable.offset = page * pageLimit - pageLimit;
      return pageable;
    });
  };

  const onDataChangeHandler = () => {
    getAlertsFromAPI(paginationFilter);
  };
  //---------------------------------------------------------------------------------Functions

  return (
    <>
      <FilterComponent onSearch={onSearchHandler} notFilter={notFilter}/>
      <div className="h-full flex flex-col mt-[20px]">
        <div
          className="flex flex-wrap content-start overflow-y-auto h-[70%] overflow-x-hidden border-[1px] rounded-xl border-purple-300 shadow-inner py-[5px]"
          id="style-scroll-bar"
        >
          {alerts.alerts.map((alert) =>
            alertDataCard(alert, onEditAlertHandler)
          )}
        </div>
        <EditAlert
          showEditAlert={showEditAlert}
          onCloseEditAlertHandler={onCloseEditAlertHandler}
          alertId={selectedAlertId}
          dataChangeHandler={onDataChangeHandler}
        />
        <div id="pagination" className={stylePagination.container}>
          <div id="filter-prev-btn" className={stylePagination.btn}>
            <Pagination
              count={totalPage}
              shape="rounded"
              onChange={(event, page) => {
                onPaginationChangeHandler(page);
              }}
              page={currentPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
