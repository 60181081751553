import "@vonage/vwc-icon";
import { useState, useEffect, useCallback } from "react";
import useHttp from "../../../hooks/useHttp";
import { useOktaAuth } from "@okta/okta-react";
import { PagableFilter } from "../../../dto/ApplicationDTO.ts";
import { getAllAlerts } from "../../../service/Endpoints";
import { AlertDetailsRespose } from "../../../dto/ResponeDTO.ts";
import Loader from "../../utils/Loader.jsx";
import EditAlert from "../common/EditAlert.jsx";

function stringToLightColor(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xFF;
      value = Math.floor((value + 255) / 2);
      color += ('00' + value.toString(16)).slice(-2);
  }

  return color;
}

export default function NewAlerts({ showAlertDetail }) {
  //hooks
  const { isLoading, error, sendRequest: callApi } = useHttp();
  const { authState, oktaAuth } = useOktaAuth();

  //state
  const [newAlerts, setNewAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showEditAlert, setShowEditAlert] = useState(false);
  const [selectedAlertId, setSelectedAlertId] = useState();

  useEffect(() => {
    setLoading(true);
    getAlertsFromAPI();
  }, []);
  
  const getAlertsFromAPI = useCallback(() => {
    let token = authState.accessToken.accessToken;
    let pageable = new PagableFilter();
    pageable.limit = 20;
    pageable.offset = 0;
    pageable.addFilter("status","NEW")
    callApi(
      { url: getAllAlerts(pageable) },
      (responseData) => {
        const response = new AlertDetailsRespose();
        response.applyData(responseData);
        setNewAlerts(response.alerts);
      },
      token
    );
    setLoading(false);
  });

  const tabColour = [
    "bg-gradient-to-r from-indigo-200",
    "bg-gradient-to-r from-yellow-200",
    "bg-gradient-to-r from-purple-200",
    "bg-gradient-to-r from-red-200",
    "bg-gradient-to-r from-green-200",
    "bg-gradient-to-r from-cyan-200",
    "bg-gradient-to-r from-lime-200",
  ];

  const trasformTime = (selectedDate) => {
    var today = new Date();
    var date_to_reply = new Date(selectedDate);
    var dayDiff = today.getDate() - date_to_reply.getDate();
    if (dayDiff === 0) return "created Today";
    else if (dayDiff === 1) return `${dayDiff} day ago`;
    else if (dayDiff > 1) return `${dayDiff} days ago`;
  };

  const onDataChangeHandler = () => {
    getAlertsFromAPI();
  };

  const onAlertDetailBtnHandler = (alertId) => {
    setShowEditAlert(true);
    setSelectedAlertId(alertId);
  };

  const onCloseEditAlertHandler = () => {
    setShowEditAlert(false);
  };

  return (
    <>
      <div className="w-full h-full rounded-xl flex flex-col p-2">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div id="header" className="flex flex-row mt-5 mb-2 mx-5">
              <div>
                <span className="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-red-400 opacity-30"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                <vwc-icon
                  type="notification-on-line"
                  size="large"
                  style={{ color: "#8A2BE2" }}
                />
              </div>
              <div className="text-sm font-extrabold text-gray-700 ml-2">
                New Alerts
              </div>
            </div>
            <div
              className="no-scrollbar mt-5
                         rounded-xl overflow-auto h-full bg-opacity-80"
            >
              {newAlerts.map((alert, index) => {
                return (
                  <div
                    key={index}
                    className={`flex flex-row mb-5 justify-evenly rounded-2xl h-[60px] alert-card-entry-animation
                rounded-[20px]} ${
                  tabColour[index % tabColour.length]
                } neo-white`}
                  >
                    <button
                      className="opacity-40 active:bg-slate-500 rounded-2xl"
                      onClick={() => onAlertDetailBtnHandler(alert.alertId)}
                    >
                      <vwc-icon type="more-vertical-line"></vwc-icon>
                    </button>
                    <div className="w-96 text-xs font-semibold mx-3 my-auto text-gray-700 tracking-tighter">
                      {alert.alertId}
                      <div className="max-w-fit text-xs font-lg text-blue-700 ml-1 my-auto bg-white shadow-2xl rounded-2xl px-2 border-2 border-blue-600 tracking-tighter">
                        {alert.alertType.toLowerCase()}
                      </div>
                    </div>
                    <div className="w-70 text-red-600 text-xs">
                      {trasformTime(alert.createdOn)}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
      <EditAlert
        showEditAlert={showEditAlert}
        onCloseEditAlertHandler={onCloseEditAlertHandler}
        alertId={selectedAlertId}
        dataChangeHandler={onDataChangeHandler}
      />
    </>
  );
}
