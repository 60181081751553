import * as React from "react";
import PieStat from "./PieStat";
import NewAlerts from "./NewAlerts";
import UserData from "./UserData";
import { PageLoaderContext } from "../../utils/PageLoader";
import { useContext } from "react";
import { AppDataContext } from "../../../App";
import LineStat from "./LineStat";
export default function Dashboard() {
  const authContext = useContext(PageLoaderContext);
  const selectTabContext = useContext(AppDataContext);
  selectTabContext.setSelectedTab(0);
  authContext.showLoader(false);

  return (
    <div className="flex flex-row h-full">
      <div className="w-full h-full">
        <div className="w-full h-full flex flex-row">
          <div className="w-4/12">
            <UserData />
          </div>
          
          <div
            id="new-alerts"
            className="dasboard-container w-4/12 bg-white flex flex-col rounded-3xl
                       overflow-true shadow-lg ml-10"
          >
            <NewAlerts />
          </div>
          <div
            id="new-alerts"
            className=" w-4/12  flex flex-col rounded-3xl
                       overflow-true  ml-10"
          >
            <LineStat />
            <PieStat />
            
          </div>
        </div>
      </div>
    </div>
  );
}
